import { template as template_01712bcda9ad49b7a6c8179334cb018b } from "@ember/template-compiler";
const FKLabel = template_01712bcda9ad49b7a6c8179334cb018b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
