import { template as template_481be3fb864e4d34ac6042f47f2d9998 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const ActionList = template_481be3fb864e4d34ac6042f47f2d9998(`
  {{#if @postNumbers}}
    <div class="post-actions" ...attributes>
      {{icon @icon}}
      {{#each @postNumbers as |postNumber|}}
        <a href="{{@topic.url}}/{{postNumber}}">#{{postNumber}}</a>
      {{/each}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionList;
